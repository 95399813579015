<template>
  <v-app-bar color="white" flat>
    <div class="d-flex align-center">
      <h1><router-link to="/" :style="logoStyle">Teamvis.</router-link></h1>
    </div>

    <v-spacer></v-spacer>
    <v-btn text href="/#procedure" color="#333">
      {{ $t("howtouse") }}
    </v-btn>
    <v-btn
      text
      href="https://drive.google.com/file/d/18Ik5fkQOkuPsd4CxGvKqDqv6VQVbB9ma/view?usp=sharing"
      target="_blank"
      rel="noopener noreferrer"
      color="#333"
    >
      {{ $t("examples") }}
    </v-btn>
    <v-btn text href="https://ischool.or.jp/" color="#333">
      {{ $t("aboutus") }}
    </v-btn>
    <v-btn text color="#333" class="mr-5" @click="switch_lang()">JP/EN</v-btn>
    <v-btn
      v-if="authState === 'signedin'"
      to="/membership"
      color="#ff9900"
      :authState="authState"
    >
      {{ $t("membership") }}
    </v-btn>
    <v-btn v-else to="/signin" color="#ff9900">{{ $t("login") }}</v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: ["authState", "user_props"],
  data: () => {
    return {
      logoStyle: {
        "text-decoration": "none",
        color: "black",
        corsor: "pointer",
      },
    };
  },
  methods: {
    switch_lang: function() {
      if (this.$root.$i18n.locale == "ja") {
        this.$root.$i18n.locale = "en";
      } else {
        this.$root.$i18n.locale = "ja";
      }
    },
  },
};
</script>
