<head>
  <title>Checkout</title>
  <script src="https://js.stripe.com/v3/"></script>
</head>
<template>
  <v-row class="text-center">
    <v-col cols="12">
      <!-- 解析画面 -->
      <div class="gray pa-10" v-if="screenStatus === 0">
        <h2 class="my-30 pb-2">{{ $t("analyzer_title") }}</h2>
        <v-tabs centered background-color="#f5f5f5">
          <v-tab>{{ $t("analyzer_type_1") }}</v-tab>
          <v-tab>{{ $t("analyzer_type_2") }}</v-tab>
          <v-tab>{{ $t("analyzer_type_3") }}</v-tab>

          <!-- tab1 -->
          <v-tab-item active-class="gray pt-8">
            <p class="pb-3">
              {{ $t("analyzer_type_1_text") }}
              <a href="#detail" class="link_lite">[{{ $t("detail") }}]</a>
            </p>
            <div class="form">
              <v-row>
                <v-text-field
                  v-model="email"
                  :label="$t('form_email')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("form_email_detail") }}
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="meeting"
                  :counter="20"
                  :label="$t('form_mtg_name')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("form_mtg_name_detail") }}
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-select
                  v-model="members"
                  :items="items"
                  :label="$t('form_no')"
                  required
                ></v-select>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_no_detail") }}</span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="start"
                  :label="$t('form_datetime')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_date_detail") }}</span>
                </v-tooltip>
              </v-row>
            </div>
            <input
              style="display: none"
              ref="input"
              type="file"
              :accept="file.mimeTypes"
              multiple
              @change="selectedFile('lite', '/AudioRecord/')"
            />
            <div
              class="drop_area my-5"
              v-if="!isUpload"
              @click="btnclick"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'lite', '/AudioRecord/')"
            >
              {{ $t("uploader_1_main") }}
            </div>
            <div class="gray my-5" />
            <div v-if="isUpload" class="mb-3">
              {{ $t("uploaded_1") }}: "{{ filename }}" {{ upload_message }}
            </div>
            <input
              style="display: none"
              ref="input_json"
              type="file"
              multiple
              @change="selectedFile('json', '/json/')"
            />
            <div
              class="drop_area json_lp my-0"
              v-if="!isUpload_json"
              @click="btnclick_json"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'json', '/json/')"
            >
              {{ $t("uploader_json") }}<br />
              {{ $t("upload") }}
            </div>
            <div v-if="isUpload_json" class="mt-3">
              json: "{{ filename_json }}" {{ upload_message_json }}
            </div>
            <div class="gray my-5" />
            <div class="mt-5" v-if="show_dur_lp == 1">
              {{ $t("charge_msg1_1") }}:{{ duration_lp
              }}{{ $t("charge_msg1_2") }}:{{ charge_lp
              }}{{ $t("charge_msg1_3") }}
            </div>
            <div
              class="mt-5"
              v-if="show_dur_lp == 2 && user_props == 'metered'"
            >
              {{ $t("charge_msg1_4") }}
            </div>
            <div class="gray my-5" />
            <v-btn color="#e03616" x-large dark @click="send_json('lite')">
              <v-icon>{{ $t("start_analysis") }}</v-icon>
            </v-btn>
          </v-tab-item>

          <!-- tab2 -->
          <v-tab-item active-class="gray pt-5">
            <p class="pb-3">
              {{ $t("analyzer_type_2_text") }}
              <a href="#detail" class="link_lite">[{{ $t("detail") }}]</a>
            </p>
            <div class="form">
              <v-row>
                <v-text-field
                  v-model="email"
                  :label="$t('form_email')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("form_email_detail") }}
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="meeting"
                  :counter="20"
                  :label="$t('form_mtg_name')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_mtg_name_detail") }}</span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-select
                  v-model="members"
                  :items="items"
                  :label="$t('form_no')"
                  required
                ></v-select>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_no_detail") }}</span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="start"
                  :label="$t('form_datetime')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_date_detail") }}</span>
                </v-tooltip>
              </v-row>
            </div>
            <input
              style="display: none"
              ref="input_all"
              type="file"
              :accept="file.mimeTypes"
              multiple
              @change="selectedFile('main_all', '/')"
            />
            <div
              class="drop_area zoom my-5"
              v-if="!isUpload_all"
              @click="btnclick_all"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'main_all', '/')"
            >
              {{ $t("uploader_2_mainall") }}<br />
              {{ $t("upload") }}
            </div>
            <div class="gray my-5" />
            <div v-if="isUpload_all">
              {{ $t("uploaded_2_1") }}: "{{ filename_all }}"
            </div>
            <input
              style="display: none"
              ref="input"
              type="file"
              :accept="file.mimeTypes"
              multiple
              @change="selectedFile('main', '/AudioRecord/')"
            />
            <div
              class="drop_area zoom my-5"
              v-if="!isUpload"
              @click="btnclick"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'main', '/AudioRecord/')"
            >
              {{ $t("uploader_2_main") }}<br />
              {{ $t("upload") }}
            </div>
            <div v-if="isUpload" class="mt-3">
              {{ $t("uploaded_2_2") }}: "{{ filename }}" {{ upload_message }}
            </div>
            <div class="gray my-3" />
            <div class="drop_main my-0">
              <input
                style="display: none"
                ref="input_hybr"
                type="file"
                :accept="file.mimeTypes"
                multiple
                @change="selectedFile('main_hybr', '/AudioRecordCakeWalk/')"
              />
              <div
                class="drop_area hybr my-0"
                v-if="!isUpload_hybr"
                @click="btnclick_hybr"
                @dragover.prevent
                @drop.prevent="
                  dropFile($event, 'main_hybr', '/AudioRecordCakeWalk/')
                "
              >
                {{ $t("uploader_2_hybr") }}<br />
                {{ $t("upload") }}
              </div>
              <input
                style="display: none"
                ref="input_json"
                type="file"
                multiple
                @change="selectedFile('json', '/json/')"
              />
              <div
                class="drop_area json my-0"
                v-if="!isUpload_json"
                @click="btnclick_json"
                @dragover.prevent
                @drop.prevent="dropFile($event, 'json', '/json/')"
              >
                {{ $t("uploader_json") }}<br />
                {{ $t("upload") }}
              </div>
            </div>
            <div v-if="isUpload_hybr">
              {{ $t("uploaded_2_3") }}: "{{ filename_hybr }}"
              {{ upload_message_hybr }}
            </div>
            <div v-if="isUpload_json" class="mt-3">
              json: "{{ filename_json }}" {{ upload_message_json }}
            </div>
            <div class="gray my-5" />
            <div class="mt-5" v-if="show_dur_main == 1 && isUpload_all">
              {{ $t("charge_msg2_1") }}:{{ duration_main
              }}{{ $t("charge_msg1_2") }}:{{ charge_main
              }}{{ $t("charge_msg1_3") }}
            </div>
            <div
              class="mt-5"
              v-if="show_dur_main == 2 && user_props == 'metered'"
            >
              {{ $t("charge_msg1_4") }}
            </div>
            <div class="gray my-5" />
            <v-btn color="#e03616" x-large dark @click="send_json('main')">
              <v-icon>{{ $t("start_analysis") }}</v-icon>
            </v-btn>
          </v-tab-item>

          <!-- tab3 -->
          <v-tab-item active-class="gray pt-5">
            <p class="pb-3">
              {{ $t("analyzer_type_3_text") }}
              <a href="#detail" class="link_lite">[{{ $t("detail") }}]</a>
            </p>
            <div class="form">
              <v-row>
                <v-text-field
                  v-model="email"
                  :label="$t('form_email')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>
                    {{ $t("form_email_detail") }}
                  </span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="meeting"
                  :counter="20"
                  :label="$t('form_mtg_name')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_mtg_name_detail") }}</span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-select
                  v-model="members"
                  :items="items"
                  :label="$t('form_no')"
                  required
                ></v-select>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_no_detail") }}</span>
                </v-tooltip>
              </v-row>
              <v-row>
                <v-text-field
                  v-model="start"
                  :label="$t('form_datetime')"
                  required
                ></v-text-field>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      mdi-alert-circle
                    </v-icon>
                  </template>
                  <span>{{ $t("form_date_detail") }}</span>
                </v-tooltip>
              </v-row>
            </div>
            <input
              style="display: none"
              ref="input"
              type="file"
              :accept="file.mimeTypes"
              multiple
              @change="selectedFile('pers', '/AudioRecord/')"
            />
            <div
              class="drop_area zoom my-5"
              v-if="!isUpload"
              @click="btnclick"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'pers', '/AudioRecord/')"
            >
              {{ $t("uploader_3_pers") }}<br />
              {{ $t("upload") }}
            </div>
            <div class="gray my-5" />
            <div v-if="isUpload" class="mb-3">
              Uploaded: "{{ filename }}" {{ upload_message }}
            </div>
            <input
              style="display: none"
              ref="input_json"
              type="file"
              multiple
              @change="selectedFile('json', '/json/')"
            />
            <div
              class="drop_area json_lp my-0"
              v-if="!isUpload_json"
              @click="btnclick_json"
              @dragover.prevent
              @drop.prevent="dropFile($event, 'json', '/json/')"
            >
              {{ $t("uploader_json") }}<br />
              {{ $t("upload") }}
            </div>
            <div v-if="isUpload_json" class="mt-3">
              json: "{{ filename_json }}" {{ upload_message_json }}
            </div>
            <div class="gray my-5" />
            <div class="mt-5" v-if="show_dur_lp == 1">
              {{ $t("charge_msg1_1") }}:{{ duration_lp
              }}{{ $t("charge_msg1_2") }}:{{ charge_lp
              }}{{ $t("charge_msg1_3") }}
            </div>
            <div
              class="mt-5"
              v-if="show_dur_lp == 2 && user_props == 'metered'"
            >
              {{ $t("charge_msg1_4") }}
            </div>
            <div class="gray my-5" />
            <v-btn color="#e03616" x-large dark @click="send_json('pers')">
              <v-icon>{{ $t("start_analysis") }}</v-icon>
            </v-btn>
          </v-tab-item>
        </v-tabs>
      </div>
      <div class="gray pa-10" v-if="screenStatus === 1">
        <div class="loading">
          <vue-loading
            type="spin"
            color="#333"
            :size="{ width: '100px', height: '100px' }"
          ></vue-loading>
          <h2>Loading: {{ stage_percentage }}％</h2>
          <h2>Remaining Time: {{ remaining_time }}min</h2>
        </div>
      </div>
      <div class="gray pa-10" v-if="screenStatus === 2">
        <h2 class="my-30 pb-10">{{ $t("analysis_finished") }}</h2>
        <img :src="diagram_url" width="50%" />
        <div class="my-10" />
        <p class="my-30 pb-10">
          {{ result_text }}
          <br />
        </p>
        <v-btn color="#e03616" x-large dark @click="download_csv" class="my-5">
          <v-icon>{{ $t("download") }}</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-dialog v-model="dialog.show" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          {{ this.dialog.title }}
        </v-card-title>

        <v-card-text>
          {{ this.dialog.msg }}
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog.show = false">
            {{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Auth, API, Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { VueLoading } from "vue-loading-template";
import axios from "axios";

export default {
  name: "LiteAnalyzer",
  components: { VueLoading },
  props: ["authState", "Auth", "user_props", "userSub"],
  data() {
    return {
      isSingIn: false,
      file: {
        content: null,
        name: "audio_folder",
        icon: "mdi-folder-music-outline",
        mimeTypes: [
          "audio/aac",
          "audio/aiff",
          "audio/amr",
          "audio/x-ape",
          "audio/x-ms-wma",
          "video/x-ms-wma",
          "audio/au",
          "video/avi",
          "audio/flac",
          "audio/gsm",
          "audio/x-ivf",
          "application/vnd.smaf",
          "video/quicktime",
          "video/mp4",
          "video/3gpp",
          "video/3gpp2",
          "video/mj2",
          "audio/mpeg",
          "audio/mp4",
          "audio/3gpp",
          "audio/3gpp2",
          "application/vnd.mophun.certificate",
          "audio/ogg",
          "audio/oma",
          "audio/tak",
          "audio/tta",
          "audio/u16be",
          "audio/u16le",
          "audio/u24be",
          "audio/u24le",
          "audio/u32be",
          "audio/u32le",
          "audio/wav",
          "audio/x-m4a",
          "audio/x-wav",
        ],
        mimeTypesLength: [
          "audio/aac",
          "audio/amr",
          "audio/flac",
          "audio/gsm",
          "video/quicktime",
          "video/mp4",
          "video/3gpp",
          "video/3gpp2",
          "audio/mpeg",
          "audio/mp4",
          "audio/3gpp",
          "audio/3gpp2",
          "audio/ogg",
          "audio/u16be",
          "audio/u16le",
          "audio/u24be",
          "audio/u24le",
          "audio/u32be",
          "audio/u32le",
          "audio/wav",
          "audio/x-m4a",
          "audio/x-wav",
        ],
        mimeTypesMain: ["audio/x-m4a", "audio/mpeg"],
        mimeTypesMainAll: ["video/mp4", "audio/mp4", "audio/x-m4a"],
      },
      email: "",
      meeting: "",
      members: "",
      start: "",
      dialog: {
        title: "",
        msg: "",
        show: false,
      },
      is_super_user: false,
      mtgId: "",
      diagram_url: "",
      isUpload: false,
      isUpload_all: false,
      isUpload_hybr: false,
      isUpload_json: false,
      upload_message: "",
      upload_message_hybr: "",
      upload_message_json: "",
      screenStatus: 0, //もともとは0
      timerObj: null,
      mtg_info: {},
      result_text: "",
      downloadfilelist: [],
      menu2: false,
      modal2: false,
      items: [2, 3, 4, 5, 6],
      duration_main: 0, //main用
      show_dur_main: 0,
      charge_main: 0,
      duration_lp: 0, //liteとpers用
      show_dur_lp: 0,
      charge_lp: 0,
      charge: 0,
      payment: false,
      is_hybrfile_in_main: false,
    };
  },
  computed: {
    stage: function() {
      if (this.mtg_info.stage || this.mtg_info.stage === 0) {
        return this.mtg_info.stage;
      }
      return 0;
    },
    stage_percentage: function() {
      return this.mtg_info.progress;
    },
    remaining_time: function() {
      return this.mtg_info.remaining_time;
    },
  },

  methods: {
    btnclick() {
      this.$refs.input.click();
    },
    btnclick_all() {
      this.$refs.input_all.click();
    },
    btnclick_hybr() {
      this.$refs.input_hybr.click();
    },
    btnclick_json() {
      this.$refs.input_json.click();
    },
    dropFile: async function(event, ver, path) {
      const file = event.dataTransfer.files;
      if (ver == "json") {
        await this.check_upload_jsondata(file, ver, path);
      } else {
        await this.check_upload_audiodata(file, ver, path);
      }
      console.log("Enter Drop Area");
    },
    async selectedFile(ver, path) {
      let file = this.$refs.input.files;
      if (ver == "main_all") {
        file = this.$refs.input_all.files;
      } else if (ver == "main_hybr") {
        file = this.$refs.input_hybr.files;
      } else if (ver == "json") {
        file = this.$refs.input_json.files;
      }
      console.log("selectedFile");
      console.log(ver, path);
      if (ver == "json") {
        this.check_upload_jsondata(file, ver, path);
      } else {
        this.check_upload_audiodata(file, ver, path);
      }
    },

    showDialog(title, msg) {
      this.dialog.title = title;
      this.dialog.msg = msg;
      this.dialog.show = true;
    },

    check_upload_jsondata: async function(file, ver, path) {
      this.isUploading = true;
      console.log(file);
      if (file.length == 1) {
        if (file[0].name.includes(".")) {
          var error_message = this.$t("error_upload_json");
          this.showDialog(this.$t("error"), error_message);
        } else {
          console.log("json file type validated");
          this.upload_folder(file, ver, path);
        }
      }
    },

    check_upload_audiodata: async function(file, ver, path) {
      this.isUploading = true;
      console.log(file);
      let error_message = this.check_audiodata(file, ver);
      if (error_message == "") {
        this.file.content = file;
        this.upload_folder(file, ver, path);
      } else {
        this.showDialog(this.$t("error"), error_message);
      }
    },
    check_audiodata: function(file, ver) {
      let error_message = "";
      // "main","main_hybr"以外でファイル長さ取得
      this.check_duration(file, ver);
      // 適切なファイルtypeか
      if (ver == "main_all") {
        if (this.file.mimeTypesMainAll.includes(file[0].type)) {
          console.log("file type is validated");
        } else {
          error_message = this.$t("error_type_mainall");
          this.show_dur_main = 2;
        }
      } else if (ver == "main") {
        if (this.file.mimeTypesMain.includes(file[0].type)) {
          console.log("file type is validated");
        } else {
          error_message = this.$t("error_type_main");
          this.show_dur_main = 2;
        }
      } else {
        if (this.file.mimeTypes.includes(file[0].type)) {
          console.log("file type is validated");
        } else {
          error_message = this.$t("error_type");
          this.show_dur_lp = 2;
        }
      }
      // 計算できないファイルtypeであれば音声長さを表示しない
      if (this.file.mimeTypesLength.includes(file[0].type)) {
        console.log("file type is validated");
      } else {
        if (ver == "main_all") {
          this.show_dur_main = 2;
        }
        if (["lite", "pers", "main"].includes(ver)) {
          this.show_dur_lp = 2;
        }
      }
      console.log(this.show_dur_lp);
      console.log(this.show_dur_main);
      // 適切なファイル数か
      if (ver == "main_all") {
        if (file.length <= 1) {
          console.log("Number of files is validated");
        } else {
          error_message = this.$t("error_nfile_mainall");
        }
      } else if (ver == "lite") {
        console.log(error_message, ver);
        if ([1, 3, 6].includes(file.length)) {
          console.log("Number of files is validated");
        } else {
          error_message = this.$t("error_nfile_lite");
        }
      } else if (ver == "main") {
        console.log(error_message, ver);
        if (1 <= file.length) {
          console.log("Number of files is validated");
        } else {
          error_message = this.$t("error_nfile_main");
        }
      } else if (ver == "pers") {
        console.log(error_message, ver);
        if (2 <= file.length) {
          console.log("Number of files is validated");
        } else {
          error_message = this.$t("error_nfile_pers");
        }
      } else if (ver == "main_hybr") {
        if (this.is_hybrfile_in_main) {
          console.log("hybr file detected");
        } else {
          error_message = this.$t("error_hybr_valid");
        }
      }
      // 未ログイン時のファイル制限
      if (this.authState == "signedin") {
        console.log("signedin");
        if ((this.user_props == "limited") & (file[0].size >= 2000000)) {
          error_message = this.$t("error_file_size");
        }
      } else {
        console.log(file[0].size);
        if (file[0].size >= 2000000) {
          error_message = this.$t("error_file_size2");
        }
      }
      console.log(error_message);
      return error_message;
    },

    check_duration: function(file, ver) {
      var loaded_audio = 0;
      var duration_max = 0;
      for (let i = 0; i < file.length; i++) {
        let audiofile = file[i];
        const audio = document.createElement("audio");
        const fr = new FileReader();
        fr.onload = () => {
          audio.pause();
          audio.src = fr.result;
          audio.load();
        };
        fr.readAsDataURL(audiofile);
        audio.addEventListener(
          "loadedmetadata",
          function() {
            var duration = audio.duration;
            duration = Math.floor(duration / 60);
            if (duration >= duration_max) {
              duration_max = duration;
            }
            loaded_audio = loaded_audio + 1;
            if (
              file.length == loaded_audio &&
              ver == "main_all" &&
              this.user_props == "metered"
            ) {
              this.show_dur_main = 1;
              this.duration_main = duration_max;
              this.charge_main = duration_max * 5;
              this.ver = ver;
              console.log("main長さ：", this.duration_main); // 総時間の取得
            }
            if (
              file.length == loaded_audio &&
              ["lite", "pers", "main"].includes(ver) &&
              this.user_props == "metered"
            ) {
              this.show_dur_lp = 1;
              this.duration_lp = duration_max;
              this.charge_lp = duration_max * 5;
              this.ver = ver;
              console.log("lp長さ：", this.duration_lp); // 総時間の取得
            }
          }.bind(this)
        );
      }
    },

    upload_folder: function(file, ver, path) {
      const content = file;
      if ((ver == "main_hybr") & (content.length > 1)) {
        this.upload_message_hybr =
          " and " + String(content.length - 1) + " other files.";
      } else if (content.length > 1) {
        this.upload_message =
          " and " + String(content.length - 1) + " other files.";
      }

      // const uploadFolder = `mtgs/lite/AudioRecord/`;
      if (this.mtgId == "") {
        var date = new Date();
        var year = date.getFullYear();
        var month = date.getMonth() + 1;
        var day = date.getDate();
        var hour = date.getHours();
        var min = date.getMinutes();
        year = ("0" + year).slice(-2);
        month = ("0" + month).slice(-2);
        day = ("0" + day).slice(-2);
        hour = ("0" + hour).slice(-2);
        min = ("0" + min).slice(-2);
        var today = year + month + day + "-" + hour + min;
        this.mtgId = today + "_" + ver + "_" + uuidv4();
      }
      for (let j = 0; j < content.length; j++) {
        console.log(content[j].type);
        Storage.put("mtgs/" + this.mtgId + path + content[j].name, content[j], {
          level: "public",
          contentType: content[j].type,
          progressCallback: (progress) => {
            var msg_title = "ファイルアップロード / File upload";
            var msg = "";
            if (progress.loaded == progress.total) {
              msg = "アップロードが完了しました / Upload ended";
              if (ver == "main_all") {
                this.isUpload_all = true;
                this.filename_all = file[0].name;
              } else if (ver == "main_hybr") {
                this.isUpload_hybr = true;
                this.filename_hybr = file[0].name;
              } else if (ver == "json") {
                this.isUpload_json = true;
                this.filename_json = file[0].name;
              } else {
                this.isUpload = true;
                this.filename = file[0].name;
                // main音声としてhybr用のファイルの名前チェックを行う
                for (var i = 0; i < file.length; i++) {
                  if (
                    file[i].name.includes("eam") &&
                    file[i].name.includes("JSIC")
                  ) {
                    this.is_hybrfile_in_main = true;
                  } else if (file[i].name.includes("ischool")) {
                    this.is_hybrfile_in_main = true;
                  } else if (file[i].name.includes("i.school")) {
                    this.is_hybrfile_in_main = true;
                  }
                }
              }
            } else if (parseInt(progress.loaded) && parseInt(progress.total)) {
              msg =
                "アップロード中 / Uploading... " +
                Math.floor(
                  (100.0 * parseInt(progress.loaded)) / parseInt(progress.total)
                ) +
                " %";
            }
            this.showDialog(msg_title, msg);
          },
        })
          .then(() => {
            // response 処理
            // progressCallback 内で完了チェックするのでここはpass
          })
          .catch(function(error) {
            // error 処理
            console.error(error);
          });
      }
    },
    check_data: function(jsonData, ver) {
      // meeting名が存在しているか
      let error_message = "";
      let pattern = /^\d{4}-?\d{2}-?\d{2} ?\d{2}:?\d{2}:?\d{2}$/g;
      console.log(pattern);
      let email_pattern = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
      if (
        jsonData["start"] != null &&
        jsonData["start"].match(pattern) == null
      ) {
        error_message = this.$t("error_form_datetime");
      }
      if (!Number.isInteger(jsonData["members"]) || jsonData["members"] == "") {
        error_message = this.$t("error_form_number");
      }
      if (jsonData["meeting"] == "") {
        error_message = this.$t("error_form_mtg");
      }
      if (jsonData["email"].match(email_pattern) == null) {
        error_message = this.$t("error_form_email");
      }
      if (jsonData["email"] == "") {
        error_message = this.$t("error_form_nomail");
      }
      if (ver == "main") {
        if (this.isUpload & this.isUpload_all) {
          console.log("file loading is OK");
        } else {
          error_message = this.$t("error_form_noaudio");
        }
      }
      return error_message;
    },

    // main
    send_json: async function(ver) {
      let jsonData = {
        email: this.email,
        meeting: this.meeting,
        members: Number(this.members),
        stage: 0,
        start: this.start || null,
        progress: 1,
        remaining_time: 10,
        duration_lp: this.duration_lp,
        charge_lp: this.charge_lp,
        show_dur_lp: this.show_dur_lp,
        duration_main: this.duration_main,
        charge_main: this.charge_main,
        show_dur_main: this.show_dur_main,
        locale: this.$root.$i18n.locale,
        user_props: this.user_props,
      };
      let error_message = this.check_data(jsonData, ver);
      if (error_message == "") {
        if (this.mtgId == "") {
          this.mtgId = uuidv4();
        }
        console.log(this.mtgId);
        Storage.put("mtgs/" + this.mtgId + "/mtg_info.json", jsonData, {
          level: "public",
          contentType: "application/json",
          progressCallback: (progress) => {
            var msg = progress.loaded + " / " + progress.total;
            console.log(msg);
            if (progress.loaded == progress.total) {
              this.showDialog("開始", "解析を開始します");
              console.log("ver:", ver);
              this.batch(ver);
            }
          },
        })
          .then(() => {
            // response 処理
          })
          .catch(function(error) {
            // error 処理
            console.error(error);
          });
      } else {
        this.showDialog(this.$t("error"), error_message);
      }
    },
    getUrl: async function(key, handler) {
      const dataExpireSeconds = 60 * 60; // 1時間有効なURLを取得
      Storage.get("mtgs/" + this.mtgId + "/" + key, {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((result) => {
          console.log(result);
          handler(result);
        })
        .catch((err) => {
          console.error(err);
        });
    },
    loadMtgInfo: function(callback) {
      const dataExpireSeconds = 60;
      Storage.get("mtgs/" + this.mtgId + "/mtg_info.json", {
        level: "public",
        expires: dataExpireSeconds,
      })
        .then((url) => {
          this.axios.get(url).then((result) => {
            this.mtg_info = result.data;
            callback();
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },

    batch: async function(ver) {
      if (!this.mtgId) return;
      if (ver == "main") {
        ver = "full"; // ver for batch
      }
      const myInit = {
        headers: {
          "content-type": "application/json",
        },
        queryStringParameters: {
          mtgId: this.mtgId,
          ver: ver,
          userSub: this.userSub,
        },
      };
      const response = await API.get("batch", "/batch", myInit);
      this.batch_msg = response.message;
      console.log(this.batch_msg);
      this.start_time();
    },

    // batch後定期的にs3を確認する処理(10秒ずつ)
    start_time: function() {
      this.timerObj = setInterval(this.watch_s3, 10000);
      this.screenStatus = 1; //timerがONであることを状態として保持
    },
    stop_time: function(finished) {
      clearInterval(this.timerObj);
      this.screenStatus = finished ? 2 : 0; //timerがOFFであることを状態として保持
    },

    // main
    download_csv: function() {
      for (let i = 0; i < this.downloadfilelist.length; i++) {
        this.getUrl(this.downloadfilelist[i], (result) => {
          axios({
            url: result,
            method: "GET", // POSTでもok
            responseType: "blob", // これがないと文字化けする
          }).then((response) => {
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", this.downloadfilelist[i]); //ここらへんは適当に設定する
            document.body.appendChild(link);
            link.click();
            link.revokeObjectURL();
          });
        });
      }
    },

    watch_s3: function() {
      console.log("watch mtg_info");
      // mtg_infoの情報を取得
      this.loadMtgInfo(() => {
        console.log(this.mtg_info);
        if (this.mtg_info["error_message"]) {
          // failed stop
          this.stop_time(false);
          this.showDialog(this.$t("error"), this.mtg_info["error_message"]);
        } else if (this.stage == 8) {
          // finished stop
          this.stop_time(true);
          this.result_text = this.mtg_info["text"];
          this.downloadfilelist = this.mtg_info["downloadfilelist"];
          this.getUrl(this.mtg_info["displayfile"], (result) => {
            this.diagram_url = result;
          });
        }
      });
    },
  },
  async created() {
    const userInfo = await Auth.currentUserInfo();
    if (userInfo) {
      this.email = userInfo.attributes.email;
    }
  },
};
</script>

<style lang="scss" scoped>
.gray {
  background-color: #f5f5f5;
}

.drop_area {
  color: gray;
  font-weight: bold;
  font-size: 1em;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 422px;
  height: 80px;
  border: 3px solid gray;
  border-radius: 15px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}
.drop_area.zoom {
  height: 80px;
}
.drop_area.hybr {
  margin-right: 10px;
  margin-left: 10px;
  height: 80px;
  width: 200px;
  color: lightgray;
  border: 3px solid lightgray;
  justify-content: center;
  align-items: center;
}
.drop_area.json {
  margin-right: 10px;
  margin-left: 10px;
  height: 80px;
  width: 200px;
  color: lightgray;
  border: 3px solid lightgray;
  justify-content: center;
  align-items: center;
}
.drop_area.json_lp {
  height: 80px;
  color: lightgray;
  border: 3px solid lightgray;
  justify-content: center;
  align-items: center;
}
.drop_main {
  display: flex;
  justify-content: center;
}

.text-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: Montserrat;
}

.v-text-field {
  width: 400px;
}

.link_lite {
  text-decoration: none;
}

h2 {
  color: #5e5e5e;
}
h3 {
  color: #5e5e5e;
}

@media screen and (max-width: 500px) {
  .v-text-field {
    width: 250px;
  }
  .drop_area {
    color: gray;
    font-weight: bold;
    font-size: 1em;
    cursor: default;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 100px;
    border: 3px solid gray;
    border-radius: 15px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
  }
}
</style>
