<template>
  <v-app>
    <EnvMark />
    <v-main>
      <div v-if="CONST.IS_LITE">
        <LiteHeader :authState="authState" :user_props="user_props" />
        <router-view
          name="lite"
          :authState="authState"
          :user_props="user_props"
          :userSub="userSub"
          :mtgHistory="mtgHistory"
          :usageSummary="usageSummary"
          :usage="usage"
          :email="email"
          :stripe_id="stripe_id"
        />
        <LiteFooter />
      </div>
      <div v-else>
        <Header :authState="authState" />
        <amplify-authenticator>
          <div v-if="authState === 'signedin'">
            <router-view />
          </div>
        </amplify-authenticator>
      </div>
    </v-main>
  </v-app>
</template>

<script>
import EnvMark from "./components/EnvMark";
import Header from "./components/Header";
import LiteHeader from "./components/lite/LiteHeader";
import LiteFooter from "./components/lite/LiteFooter";
import { CONST } from "./const";
import { Auth, API } from "aws-amplify";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

export default {
  components: { EnvMark, Header, LiteHeader, LiteFooter },
  async created() {
    // check user
    onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      this.updateUserInfo();
      if (authState === "signedin" && authData) {
        //  console.log("signed in.");
        //  console.log(authData);
        this.$router.push({ name: "Home" });
      }
    });
    if (this.authState === undefined) {
      Auth.currentAuthenticatedUser().then((authData) => {
        this.authState = AuthState.SignedIn;
        this.user = authData;
        this.updateUserInfo();
      });
    }
  },
  methods: {
    async updateUserInfo() {
      // decide userprops
      const userInfo = await Auth.currentUserInfo();
      if (this.authState === "signedin") {
        if (userInfo) {
          console.log(userInfo.attributes);
          this.email = userInfo.attributes.email;
          this.userSub = userInfo.attributes.sub;
          if ("custom:stripe_id" in userInfo.attributes) {
            this.stripe_id = userInfo.attributes["custom:stripe_id"];
          }
          if ("custom:subscription_id" in userInfo.attributes) {
            this.subscription_id =
              userInfo.attributes["custom:subscription_id"];
            // 未払い請求情報の取得
            const myInit_usage = {
              headers: {
                "content-type": "application/json",
              },
              queryStringParameters: {
                userSub: userInfo.attributes.sub,
                stripe_id: userInfo.attributes["custom:stripe_id"],
              },
            };
            const usage = await API.get("usage", "/get", myInit_usage);
            console.log(usage);
            this.usage = usage["amount_remaining"];
          }
          // DB内user情報の取得
          const myInit = {
            headers: {
              "content-type": "application/json",
            },
            queryStringParameters: {
              userSub: userInfo.attributes.sub,
            },
          };
          const response = await API.get("user", "/info", myInit);
          this.is_super_user = response["is_super_user"];
          this.mtgHistory = response["mtg_history"];
          this.usageSummary = response["usage_summary"];
        }
        // superuserであればunlimited
        if (this.is_super_user) {
          this.user_props = "unlimited";
        } else if (!this.subscription_id) {
          this.user_props = "limited";
        } else {
          this.user_props = "metered";
        }
      } else {
        this.user_props = "limited";
      }
      console.log(this.user_props);
      console.log(this.email);
    },
  },
  data() {
    return {
      CONST,
      user: undefined,
      authState: undefined,
      // 無制限ユーザー"unlimited"、課金ユーザー"metered"、制限ユーザー"limited"
      user_props: undefined,
      email: undefined,
      userSub: undefined,
      stripe_id: undefined,
      mtgHistory: [],
      usageSummary: undefined,
      usage: undefined,
    };
  },

  // beforeDestroy() {
  //   return onAuthUIStateChange;
  // },
};
</script>
