<template>
  <v-row class="text-center">
    <v-col cols="12">
      <!-- 説明画面1_紹介 -->
      <div class="pa-10">
        <v-container>
          <div class="my-5" />
          <v-row class="align-center">
            <v-col md="6">
              <v-img
                src="../../assets/201014_WS8B_1_Diagram.png"
                class="ml-auto"
              />
            </v-col>
            <v-col md="5" offset-md="1">
              <h2 class="font-weight-bold text-left">
                {{ $t("intro1_title_1") }}
                <br />{{ $t("intro1_title_2") }}
              </h2>
              <div class="my-5" />
              <p class="font-weight-regular text-left mb-0">
                {{ $t("intro1_text_1") }}
                <a
                  href="https://drive.google.com/file/d/18Ik5fkQOkuPsd4CxGvKqDqv6VQVbB9ma/view?usp=sharing"
                  target="_blank"
                  rel="noopener noreferrer"
                  class="link_lite"
                  >[{{ $t("examples") }}]</a
                >{{ $t("intro1_text_2") }}
                <br />
                <br />
                {{ $t("usage_example") }}
              </p>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("usage_example_text1") }}
                </li>
                <li class="font-weight-regular text-left">
                  {{ $t("usage_example_text2") }}
                </li>
              </ul>
              <br />
            </v-col>
          </v-row>
        </v-container>
      </div>
      <div class="my-7" />

      <!-- 説明画面2_紹介 -->
      <div class="gray pa-10">
        <v-container>
          <v-row class="align-center">
            <v-col md="5">
              <h2 class="font-weight-bold text-left my-3">
                {{ $t("intro2_title") }}
              </h2>
              <p class="font-weight-regular text-left">
                {{ $t("intro2_text1") }}
              </p>
              <ul>
                <li class="font-weight-regular text-left mb-2">
                  {{ $t("intro2_text2") }}
                </li>
                <li class="font-weight-regular text-left mb-2">
                  {{ $t("intro2_text3") }}
                </li>
              </ul>
            </v-col>
            <v-col md="6" offset-md="1">
              <div class="my-10" />
              <v-img src="../../assets/analysis.png" class="ml-auto" />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- 説明画面3_分析手順 id=procedure -->
      <div class="pa-10">
        <v-container>
          <v-row class="align-center">
            <v-col md="5">
              <h2 class="my-3 font-weight-bold text-left" id="procedure">
                {{ $t("procedure_title") }}
              </h2>
              <div class="text-left">
                <h3>{{ $t("procedure_list_1") }}</h3>
              </div>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("procedure_text_1") }}
                </li>
                <li class="font-weight-regular text-left">
                  {{ $t("procedure_text_2") }}
                  <a
                    href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("procedure_text_3") }}</a
                  >
                  {{ $t("procedure_text_4") }}
                  <br />
                  <a href="#detail" class="link_lite">
                    ⇨ {{ $t("Type of analysis") }}
                  </a>
                  <br />
                  <a href="#better_analysis" class="link_lite">
                    ⇨ {{ $t("to_get_better_result") }}
                  </a>
                </li>
              </ul>
              <br />
              <div class="text-left">
                <h3>{{ $t("procedure_list_2") }}</h3>
              </div>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("procedure_text_5") }}
                </li>
              </ul>
              <br />
              <div class="text-left mb-3">
                <h3>{{ $t("procedure_list_3") }}</h3>
              </div>
            </v-col>
            <v-col md="6" offset-md="1">
              <div class="my-5" />
              <v-img src="../../assets/procedure.png" class="ml-auto" />
            </v-col>
          </v-row>
        </v-container>
      </div>

      <!-- 説明画面4_解析の詳細 id="detail" -->
      <div class="gray pa-10">
        <v-container>
          <v-row>
            <v-col md="12">
              <h2 class="font-weight-bold text-left my-3" id="detail">
                {{ $t("Type of analysis") }}
              </h2>
              <v-simple-table class="mb-3">
                <thead>
                  <tr>
                    <th class="text-center font-weight-regular">
                      {{ $t("no_files") }}
                    </th>
                    <th class="text-center font-weight-regular">
                      {{ $t("detail") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="text-center font-weight-regular">
                      {{ $t("type_1") }}
                    </td>
                    <td class="text-left font-weight-regular">
                      {{ $t("type_1_text") }}
                      <a
                        href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ $t("here") }}</a
                      >{{ $t("type_1_text2") }}
                      <div v-if="user_props === 'metered'">
                        {{ $t("type_1_chg") }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center font-weight-regular">
                      {{ $t("type_2") }}
                    </td>
                    <td class="text-left font-weight-regular">
                      {{ $t("type_2_text") }}
                      <a
                        href="https://support.zoom.us/hc/ja/articles/201362473-%E3%83%AD%E3%83%BC%E3%82%AB%E3%83%AB-%E3%83%AC%E3%82%B3%E3%83%BC%E3%83%87%E3%82%A3%E3%83%B3%E3%82%B0%E3%82%92%E6%9C%89%E5%8A%B9%E3%81%AB%E3%81%97%E3%81%A6%E9%96%8B%E5%A7%8B%E3%81%99%E3%82%8B"
                        target="_blank"
                        rel="noopener noreferrer"
                        >{{ $t("type_2_text2") }}</a
                      >{{ $t("type_2_text3") }}
                      <div v-if="user_props === 'metered'">
                        {{ $t("type_2_chg") }}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="text-center font-weight-regular">
                      {{ $t("type_3") }}
                    </td>
                    <td class="text-left font-weight-regular">
                      {{ $t("type_3_text") }}
                      <div v-if="user_props === 'metered'">
                        {{ $t("type_1_chg") }}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
        <div class="gray my-5" />
      </div>

      <!-- 説明画面5_より良い解析 -->
      <div class="pa-10">
        <v-container>
          <v-row>
            <v-col md="12">
              <h2 class="font-weight-bold text-left my-3" id="better_analysis">
                {{ $t("to_get_better_result") }}
              </h2>
              <div class="text-left">
                <h3>{{ $t("ba_list_1") }}</h3>
              </div>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_1_text") }}
                </li>
              </ul>
              <div class="text-left">
                <h3>{{ $t("ba_list_2") }}</h3>
              </div>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_2_text1") }}
                </li>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_2_text2") }}
                </li>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_2_text3") }}
                </li>
              </ul>
              <div class="text-left">
                <h3>{{ $t("ba_list_3") }}</h3>
              </div>
              <ul>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_3_text1") }}
                  <a
                    href="https://drive.google.com/file/d/1U-Mvx2XilvMQ6H8MB_lTXXhPeH6kRZ69/view?usp=sharing"
                    target="_blank"
                    rel="noopener noreferrer"
                    >{{ $t("ba_list_3_text2") }}</a
                  >{{ $t("ba_list_3_text3") }}
                </li>
                <li class="font-weight-regular text-left">
                  {{ $t("ba_list_3_text4") }}
                </li>
                <li class="font-weight-regular text-left mb-3">
                  {{ $t("ba_list_3_text5") }}
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: ["authState", "Auth", "user_props"],
};
</script>

<style lang="scss" scoped>
.gray {
  background-color: #f5f5f5;
}

.link_lite {
  text-decoration: none;
}
h2 {
  color: #5e5e5e;
}
h3 {
  color: #5e5e5e;
}
</style>
