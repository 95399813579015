import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Lite from "../views/Lite.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    components: {
      default: Home,
      lite: Lite,
    },
  },
  {
    path: "/signin/",
    strict: true,
    name: "SignIn",
    components: {
      lite: () => import("../views/SignIn.vue"),
    },
  },
  {
    path: "/signin",
    redirect: "/signin/",
  },
  {
    path: "/mtg/:id",
    name: "Mtg",
    component: () => import("../views/Mtg.vue"),
  },
  {
    path: "/membership/",
    strict: true,
    name: "Membership",
    components: {
      lite: () => import("../views/Membership.vue"),
    },
  },
  {
    path: "/law/",
    strict: true,
    name: "ShopInfo",
    components: {
      lite: () => import("../views/ShopInfo.vue"),
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
