<template>
  <section class="home-hero">
    <LiteHome />
    <LiteCopy :authState="authState" :user_props="user_props" />
    <LiteAnalyzer
      :authState="authState"
      :user_props="user_props"
      :userSub="userSub"
    />
    <LiteDescription :authState="authState" :user_props="user_props" />
  </section>
</template>

<script>
import LiteHome from "../components/lite/LiteHome";
import LiteCopy from "../components/lite/LiteCopy";
import LiteAnalyzer from "../components/lite/LiteAnalyzer";
import LiteDescription from "../components/lite/LiteDescription";

export default {
  name: "Lite",
  props: ["authState", "user_props", "userSub"],
  components: { LiteHome, LiteCopy, LiteAnalyzer, LiteDescription },
};
</script>
